import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../../services/nav.service';
import { AuthService } from '@app/core/auth/auth.service';
import { CovidService } from '@app/core/services/covid.service';
import { CovidDto } from '@app/core/models/covid/covid-dto';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from '@app/shared/services/confirmation-dialog.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public menuItems: Menu[];
  public url: any;
  public fileurl: any;
  currentUserName: string;
  covidPageIsEnabled: boolean;
  covid: CovidDto;
  covidToogle: boolean;

  constructor (
    private router: Router, 
    public navServices: NavService, 
    public authService: AuthService,
    public covidService: CovidService,
    private toastrService: ToastrService,
    private dialog: ConfirmationDialogService, 
  ) {
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url) {
              this.setNavActive(items);
            }

            if (!items.children) {
              return false;
            }

            items.children.filter(subItems => {
              if (subItems.path === event.url) {
                this.setNavActive(subItems);
              }

              if (!subItems.children) {
                return false;
              }

              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });

    this.authService.currentUser.subscribe(data => {
      if (data) {
        this.currentUserName = data.firstName + ' ' + data.lastName;
      }
    });

    // check covid data
    this.covidPageIsEnabled = false;
    this.covidService.getPublished().subscribe(covidData => {
      this.covid = covidData;
      let today = new Date();
      this.covidPageIsEnabled = this.covid && !this.covid.disabled;
      this.covidToogle = this.covid && !this.covid.disabled;
    });
  }

  // Active Nave state
    setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

  // Auto Hide sidebar
  autoHideSideBar(){
    this.navServices.collapseSidebar = true;
    document.getElementById("sidebar-toggle-expanded").style.display = 'none';
    document.getElementById("sidebar-toggle").style.display = 'block';
  }

  // covid toggle
  toggleCovid(e: any) {

    let message:string;
    if(e.target.checked === true)
    {
      message = "Are you sure you would like to show the Legendary News Page in the intranet?"
    }
    else{
      message = "Are you sure you would like to hide the Legendary News Page from the intranet?"
    }

    this.dialog.open('Confirm Enabled/Disabled Legendary News Page', `${message}`).then(result => {
      if (result) {
        if (e.target.checked === true) {
          this.covidService.disabledEnabled(false).subscribe(data=>{
            window.location.reload();
            this.toastrService.success("Legendary News Page has been enabled", "Success");
          })
        }
        else {
          this.covidService.disabledEnabled(true).subscribe(data=>{
            window.location.reload();
            this.toastrService.success("Legendary News Page has been disabled", "Success");
          })
        }
      }
      else{
        var element = <HTMLInputElement> document.getElementById("covidSwitches");
        if(e.target.checked == true){
          element.checked = false;
        }
        else{
          element.checked = true;
        }
      }
    }).catch(() => console.log('dismissed'));
  }
}
