import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() autoHide: boolean = true;

  show: boolean = true;
  constructor() {
    if(this.autoHide){
     setTimeout(() => {
       this.show = false;
     }, 1500);
    }
  }

  ngOnInit() { }

  ngOnDestroy() { }

}
