import { Component, OnInit, AfterViewInit, HostListener,ElementRef, ViewChild } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, zoomOut, zoomIn, fadeIn, bounceIn } from 'ng-animate';
import { NavService, Menu } from '../../../services/nav.service';
import * as feather from 'feather-icons';
import { CustomizerService } from '@app/shared/services/customize.servicer';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Subject,Subscription } from 'rxjs';
import { SearchService } from '@app/core/services/search.service';
import { SearchDto } from '@app/core/models/search/search-dto'
import { Router } from '@angular/router';
import { SearchSuggestionService } from '@app/core/services/searchsuggestion.service';
import { QuickLinkDto } from '../../../../core/models/quicklink/quicklink-dto';
import { QuickLinkService } from '../../../../core/services/quicklink.service';
import { QuickLinkRowGroupDto } from '@app/core/models/quicklink/quicklink-row-group-dto';

const body = document.getElementsByTagName('body')[0];

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  animations: [
    trigger('animateRoute', [transition('* => *', useAnimation(fadeIn, {
      // Set the duration to 5seconds and delay to 2 seconds
      //params: { timing: 3}
    }))])
  ]
})
export class ContentLayoutComponent implements OnInit, AfterViewInit {
  public menuItems: Menu[];
  public items: Menu[];
  public searchResult = false;
  isSmartSearch: boolean = false;

  public text: string;
  public isOpenMobile = false;
  private searchQuery = new Subject<string>();
  @ViewChild("searchInput", { static: false }) searchInputField: ElementRef;
  searchDto: SearchDto;
  public right_side_bar: boolean;
  public searchResultEmpty = false;
  searchSuggestion: string;
  private currentResultCountSubsription: Subscription;
  private webSpeechAvailable = false;
  isSearch: boolean = false;
  quickLinks: QuickLinkDto[];
  quickLinksSecondRow: QuickLinkDto[];
  quickLinkRowGroups: QuickLinkRowGroupDto[] = [];
  quickLinkSmallDeviceRowGroups: QuickLinkRowGroupDto[] = [];

  constructor(public navServices: NavService, 
    public customizer: CustomizerService,
    private router: Router,
    private searchService: SearchService,
    private searchSuggestionService: SearchSuggestionService,
    private quickLinkService: QuickLinkService) { }


  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  @HostListener('document:click', ['$event'])
  clickedOutside(event) {
    // click outside Area perform following action
    document.getElementById('outer-container').onclick = function (e) {
      e.stopPropagation()
      if (e.target != document.getElementById('search-outer')) {
        document.getElementsByTagName("body")[0].classList.remove("offcanvas");
      }
      if (e.target != document.getElementById('outer-container') && document.getElementById("canvas-bookmark") != null) {
        document.getElementById("canvas-bookmark").classList.remove("offcanvas-bookmark");
      }

      if(e.target != document.getElementsByClassName('Typeahead-menu')[0]){
          var container = <HTMLElement>document.getElementsByClassName('Typeahead-menu is-open')[0];
          if(container != undefined){
            if(!container.classList.contains('loader'))
            {
              container.classList.remove('is-open');
            }      
          }
            
      }
    }

    var navService = this.navServices;
    var pageBodyElem = <HTMLElement>document.getElementsByClassName('page-body')[0];
    pageBodyElem.onclick = function (e) {
        document.getElementById("sidebar-toggle-expanded").style.display = 'none';
        document.getElementById("sidebar-toggle").style.display = 'block';
        navService.collapseSidebar = true;
    }

    var pageBodyElem = <HTMLElement>document.getElementsByClassName('footer')[0];
    pageBodyElem.onclick = function (e) {
        document.getElementById("sidebar-toggle-expanded").style.display = 'none';
        document.getElementById("sidebar-toggle").style.display = 'block';
        navService.collapseSidebar = true;
    }

   
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  public rightSidebar($event) {
    this.right_side_bar = $event
  }

  ngOnInit() {
    this.webSpeechAvailable = 'webkitSpeechRecognition' in window;
    this.navServices.items.subscribe(menuItems => {
      this.items = menuItems;
    });

    this.InitializeSearch();

    this.currentResultCountSubsription = this.searchService.currentResultCount.subscribe(resultCount => {
      if (resultCount === 0) {
        this.searchInputField.nativeElement.focus();
      }
    });
    this.getRandomSuggestion();

    this.getQuickLinks();
  }

  searchTerm() {
    this.searchInputField.nativeElement.value = "";
    var searchResultUrl = '/searchresult/' + this.text;
    this.router.navigateByUrl(searchResultUrl);
  }

  InitializeSearch() {
    this.searchQuery.pipe(
        debounceTime(1000),
        distinctUntilChanged()
    ).subscribe(searchQuery => {
        let query = encodeURIComponent(searchQuery);
        if(query !== ''){
            this.searchService.search(query).subscribe(data => {
              this.searchDto = data;
              this.searchResultEmpty = !this.searchDto.Events && !this.searchDto.Users;
            });
        }
    });
  }

  initiateVoiceQuery() {
    //TO BE IMPLEMENTED AT A LATER DATE.
    //CHECK HERE FOR CODE: https://github.com/luixaviles/web-speech-angular
  }

  ngOnDestroy() {
    this.removeFix();
    this.currentResultCountSubsription.unsubscribe();
  }

  removeFix() {
    this.searchResult = false;
    body.classList.remove('offcanvas');
    this.text = '';
  }

getRandomSuggestion() {
  this.searchSuggestionService.getSearchSuggestions().subscribe(suggestions => {
    if (suggestions.length > 0) {
      const randomSuggestion = Math.floor(Math.random() * suggestions.length);
      this.searchSuggestion = suggestions[randomSuggestion].suggestion;
    }
  });
}
  onSearchKeyDown(event){
    if(event.key === "Enter"){
        this.searchTerm();
    }
  }

  onResetSearch() {
    const element = document.getElementById('search-box');
    element.classList.add('d-none');
    this.removeFix();
  }

  getQuickLinks() {
    // Get Quick Links
    this.quickLinkService.getQuickLinks().subscribe(quickLinks => {
      const count = quickLinks.length;
      const perRow = Math.ceil(count / 2);
      const perRowSmallDevice = 2;

      //Large Device 
      this.quickLinkRowGroups.push({ row: 0, quickLinks: quickLinks });

      //SmallDevice
      if (count <= perRowSmallDevice) {
        this.quickLinkSmallDeviceRowGroups.push({ row: 1, quickLinks: quickLinks });
      } else {
        var row = 0;
        for (var i = 0; i < count; i += perRowSmallDevice) {
          this.quickLinkSmallDeviceRowGroups.push({ row: row++, quickLinks: quickLinks.slice(i, i + perRowSmallDevice) });
        }
      }
    });
  }

  quickLinkDetail(event, item) {
    window.open(item.urlLink);
  }
}
