///OpenCatapultModelId:959
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TrainingScheduleDto } from '../models/trainingschedule/trainingschedule-dto';
import { ApiService } from './api.service';
import { TrainingScheduleCustomDto } from '../models/trainingschedule/trainingschedule-custom-dto';
import { TrainingScheduleUpdateDto } from '../models/trainingschedule/trainingscheduleupdate-dto';
import { TrainingScheduleUpdateStatusDto } from '../models/trainingschedule/trainingscheduleupdatestatus-dto';

@Injectable({
  providedIn: 'root'
})
export class TrainingScheduleService {

  constructor(private apiService: ApiService) { }

  getTrainingSchedules(): Observable<TrainingScheduleDto[]> {
    return this.apiService.get<TrainingScheduleDto[]>('TrainingSchedule');
  }

  getCustomTrainingSchedules(week:number): Observable<TrainingScheduleCustomDto[]> {
    return this.apiService.get<TrainingScheduleCustomDto[]>(`TrainingSchedule/getcustomtrainingschedule/${week}`);
  }

  getTrainingSchedule(id: number): Observable<TrainingScheduleDto> {
    return this.apiService.get<TrainingScheduleDto>(`TrainingSchedule/${id}`);
  }

  checkRegisterStatusbyId(id: number): Observable<TrainingScheduleDto> {
    return this.apiService.get<TrainingScheduleDto>(`TrainingSchedule/checkregisterstatusbyid/${id}`);
  }

  createTrainingSchedule(dto: TrainingScheduleDto) {
    return this.apiService.post('TrainingSchedule', dto);
  }

  checkOverlapSchedule(dto: TrainingScheduleDto):Observable<boolean> {
    return this.apiService.post('TrainingSchedule/checkoverlapschedule', dto);
  }

  checkRegisteredUser(dto: TrainingScheduleDto):Observable<number> {
    return this.apiService.post('TrainingSchedule/checkregistereduser', dto);
  }

  createTrainingScheduleCustom(dto: TrainingScheduleDto) {
    return this.apiService.post('TrainingSchedule/createtrainingschedulecustom', dto);
  }

  updateTrainingSchedule(dto: TrainingScheduleDto) {
    return this.apiService.put(`TrainingSchedule/${dto.id}`, dto);
  }

  customUpdateTrainingSchedule(dto: TrainingScheduleUpdateDto): Observable<TrainingScheduleUpdateStatusDto> {
    return this.apiService.put<TrainingScheduleUpdateStatusDto>(`TrainingSchedule/edittrainingschedulecustom`, dto);
  }

  deleteTrainingSchedule(id: number) {
    return this.apiService.delete(`TrainingSchedule/${id}`);
  }

  deleteCustomTrainingSchedule(dto: TrainingScheduleDto) {
    return this.apiService.post(`TrainingSchedule/deletetrainingsession`,dto);
  }

  registerusertrainingschedule(id: number) {
    return this.apiService.post(`TrainingSchedule/registerusertrainingschedule/${id}`);
  }

  unregisterusertrainingschedule(id: number) {
    return this.apiService.post(`TrainingSchedule/unregisterusertrainingschedule/${id}`);
  }

  getTrainingScheduleByReserverAndWeek(scheduleId:number): Observable<TrainingScheduleDto> {
    return this.apiService.get<TrainingScheduleDto>(`TrainingSchedule/gettrainingschedulebyreserveremailinweek/?id=${scheduleId}`);
  }

  updateReserveTrainingSchedule(selectedScheduleId: number, previousScheduleId:number) {
    return this.apiService.post(`TrainingSchedule/updatereservedtrainingschedule/?selectedScheduleId=${selectedScheduleId}&previousScheduleId=${previousScheduleId}`);
  }

  getTrainingScheduleByDateTimeSpan(dto: TrainingScheduleDto): Observable<TrainingScheduleDto[]> {
    return this.apiService.post<TrainingScheduleDto[]>(`TrainingSchedule/gettrainingschedulebydatetimespan`,dto);
  }
}

