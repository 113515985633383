import { Injectable, HostListener, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber, Subscription } from 'rxjs';
import { AuthService } from '../../core/auth/auth.service';
import { Role } from '../../core/auth/role';

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  disallowedDevices?: string[];
}

@Injectable({
  providedIn: 'root'
})

export class NavService implements OnDestroy {

  public screenWidth: any;
  public collapseSidebar = false;

  MENUITEMSADMIN: Menu[] = [
    {
      title: 'Home Page', icon: 'home', type: 'link', path: '/'
    },
   
    {
      path: '/legendarynews', title: 'Legendary News', type: 'special-link', icon: 'fa fa-medkit'
    },
    {
      path: '/directory', title: 'Directory', type: 'link', icon: 'users'
    },
    {
      path: '/document', title: 'Documents', type: 'link', icon: 'folder'
    },
    {
      path: '/event', title: 'Events', type: 'link', icon: 'calendar'
    },
    {
      path: '/news', title: 'News', type: 'link', icon: 'rss'
    },
    //{
    //  path: '/launchpad', title: 'LaunchPad', type: 'link', icon: 'grid', disallowedDevices: ['mobile', 'tablet']
    //},
    {
      path: '/gallery', title: 'Photo Gallery', type: 'link', icon: 'image'
    },
    {
      path: '/wellness', title: 'Health & Wellness', type: 'link', icon: 'heart'
    },
    {
      title: 'Admin', icon: 'user-plus', type: 'sub', active: false, disallowedDevices: ['mobile'], children: [
        {
          path: '/admin/legendarynews', title: 'Legendary News', type: 'link', icon: 'rss'
        },
        {
          path: '/admin/banner', title: 'Home Page Banners', type: 'link', icon: 'users'
        },
        {
          path: '/admin/quicklink', title: 'QuickLinks', type: 'link', icon: 'users'
        },
        {
          path: '/admin/notification', title: 'Notifications', type: 'link'
        },
        {
          path: '/admin/notificationtype', title: 'Notification Types', type: 'link'
        },
        {
          path: '/admin/newsarticle', title: 'News', type: 'link'
        },
        {
          path: '/admin/event', title: 'Events', type: 'link'
        },
        {
          path: '/admin/gallery', title: 'Photo Gallery', icon: 'image', type: 'link'
        },
        {
          path: '/admin/documentcategory', title: 'Documents', type: 'link', icon: 'folder'
        },
        {
          path: '/admin/documentgroup', title: 'Document Groups', type: 'link', icon: 'folders'
        },
        {
          path: '/admin/wellness', title: 'Health & Wellness', type: 'link', icon: 'heart'
        },
        {
          path: '/admin/pushnotification', title: 'Push Notification', type: 'link', icon: 'folder'
        },
        {
          path: '/admin/searchsuggestion', title: 'Search Suggestion', type: 'link', icon: 'folder'
        },
      ].sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
    }];

  MENUITEMSEDITOR: Menu[] = [
    {
      title: 'Home Page', icon: 'home', type: 'link', path: '/'
    },
    {
      path: '/legendarynews', title: 'Legendary News', type: 'special-link', icon: 'fa fa-medkit'
    },
    {
      path: '/directory', title: 'Directory', type: 'link', icon: 'users'
    },
    {
      path: '/document', title: 'Documents', type: 'link', icon: 'folder'
    },
    {
      path: '/event', title: 'Events', type: 'link', icon: 'calendar'
    },
    {
      path: '/news', title: 'News', type: 'link', icon: 'rss'
    },
    //{
    //  path: '/launchpad', title: 'LaunchPad', type: 'link', icon: 'grid', disallowedDevices: ['mobile', 'tablet']
    //},
    {
      path: '/gallery', title: 'Photo Gallery', type: 'link', icon: 'image'
    },
    {
      path: '/wellness', title: 'Health & Wellness', type: 'link', icon: 'heart'
    },
    {
      title: 'Editor', icon: 'edit', type: 'sub', active: false, children: [
        {
          path: '/admin/legendarynews', title: 'Legendary News', type: 'link', icon: 'rss'
        },
        {
          path: '/admin/banner', title: 'Home Page Banners', type: 'link', icon: 'users'
        },
        {
          path: '/admin/newsarticle', title: 'News', type: 'link'
        },
        {
          path: '/admin/event', title: 'Events', type: 'link'
        },
        {
          path: '/admin/gallery', title: 'Photo Gallery', icon: 'image', type: 'link'
        },
        {
          path: '/admin/documentcategory', title: 'Documents', type: 'link', icon: 'folder'
        },
        {
          path: '/admin/documentgroup', title: 'Document Groups', type: 'link', icon: 'folders'
        }
      ].sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
    }
  ];

  MENUITEMS: Menu[] = [
    {
      title: 'Home Page', icon: 'home', type: 'link', path: '/'
    },
    {
      path: '/legendarynews', title: 'Legendary News', type: 'special-link', icon: 'fa fa-medkit'
    },
    {
      path: '/directory', title: 'Directory', type: 'link', icon: 'users'
    },
    {
      path: '/document', title: 'Documents', type: 'link', icon: 'folder'
    },
    {
      path: '/event', title: 'Events', type: 'link', icon: 'calendar'
    },
    {
      path: '/news', title: 'News', type: 'link', icon: 'rss'
    },
    //{
    //  path: '/launchpad', title: 'LaunchPad', type: 'link', icon: 'grid', disallowedDevices: ['mobile', 'tablet']
    //},
    {
      path: '/gallery', title: 'Photo Gallery', type: 'link', icon: 'image'
    },
    {
      path: '/wellness', title: 'Health & Wellness', type: 'link', icon: 'heart'
    }
  ];

  WELNESSMENUITEMS: Menu[] = [
    {
      path: '/wellness', title: 'Health & Wellness', type: 'link', icon: 'heart'
    },
    {
      title: 'Admin', icon: 'user-plus', type: 'sub', active: false, children: [
        {
          path: '/admin/wellness', title: 'Health & Wellness', type: 'link', icon: 'heart'
        }
      ].sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
    }
  ];

  isAdmin = false;
  isEditor = false;
  isWelness = false;
  // Array
  items = new BehaviorSubject<Menu[]>([]);

  private userSubscriber: Subscription;
  constructor(private authService: AuthService) {
    this.onResize();
    if (this.screenWidth < 992) {
      this.collapseSidebar = true;
    }

    this.userSubscriber = this.authService.currentUser.subscribe(user => {
      if (user != null) {
        let currentMenuItems = null;
        this.isAdmin = user.role === Role.Administrator;
        this.isEditor = user.role === Role.Editor;
        this.isWelness = user.role === Role.Wellness;

        currentMenuItems = this.MENUITEMS;

        if (this.isAdmin)
          currentMenuItems = this.MENUITEMSADMIN;
        else if (this.isEditor)
          currentMenuItems = this.MENUITEMSEDITOR;
        else if (this.isWelness)
          currentMenuItems = this.WELNESSMENUITEMS;

        currentMenuItems.forEach(x => x.active = false);
        this.items.next(currentMenuItems);
      }
    });
  }

  ngOnDestroy() {
    if (this.userSubscriber) {
      this.userSubscriber.unsubscribe();
    }
  }

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }




}
