///OpenCatapultModelId:1014
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CovidDto } from '../models/covid/covid-dto';
import { ApiService } from './api.service';
import { CovidCustomDto } from '../models/covid/covid-custom-dto';

@Injectable({
  providedIn: 'root'
})
export class CovidService {

  constructor(private apiService: ApiService) { }

  getCovids(): Observable<CovidDto[]> {
    return this.apiService.get<CovidDto[]>('Covid');
  }

  getSingleCovids(): Observable<CovidDto> {
    return this.apiService.get<CovidDto>('Covid/GetSingleCovid');
  }

  getCovid(id: number): Observable<CovidDto> {
    return this.apiService.get<CovidDto>(`Covid/${id}`);
  }

  createCovid(dto: CovidCustomDto) {
    return this.apiService.post('Covid', dto);
  }

  updateCovid(dto: CovidCustomDto) {
    return this.apiService.put(`Covid/${dto.id}`, dto);
  }

  deleteCovid(id: number) {
    return this.apiService.delete(`Covid/${id}`);
  }

  disabledEnabled(isDisabled: boolean) {
    return this.apiService.post(`Covid/disabledenabled/${isDisabled}`);
  }

  getPublished(): Observable<CovidDto> {
    return this.apiService.get<CovidDto>('Covid/GetPublishedPage');
  }
}

