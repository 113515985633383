import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { PeopleSpotlightDto } from '../models/user/people-spotlight-dto';
import { PeopleSpotlightFilterDto } from '../models/user/people-spotlight-filter-dto';

@Injectable({
  providedIn: 'root'
})

export class PeopleSpotlightService {

  constructor(private apiService: ApiService) { }

  getFiltered(filter: PeopleSpotlightFilterDto): Observable<PeopleSpotlightDto[]> {
    return this.apiService.post<PeopleSpotlightDto[]>(`PeopleSpotlight/Filtered`, filter);
  }
}
