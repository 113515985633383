import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';
import DOMPurify from 'dompurify';

@Pipe({ name: 'safeHtml', pure: true })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
    
    transform(value: string) {
        DOMPurify.setConfig({ ADD_ATTR: ['target'] });
        const safeContent = DOMPurify.sanitize(value);
        return this.sanitizer.bypassSecurityTrustHtml(safeContent);
    }
}
