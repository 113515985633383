import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { ApiService } from './api.service';
import { AboutDto } from '../models/about/about-dto';

@Injectable({
  providedIn: 'root'
})
export class AboutService {

  constructor(
    private http: HttpClient,
    private apiService: ApiService) { }

  getAppInfo() {
    var appInfo: AboutDto[] = [];

    this.http.get<AboutDto>('About').subscribe(data => {
      appInfo.push(data);
    });

    this.apiService.get<AboutDto>('About').subscribe(data => {
      appInfo.push(data);
    });

    return appInfo;
  }
}
