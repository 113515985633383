import { Component, HostListener, Inject } from '@angular/core';
import { WINDOW } from "./shared/services/window.service";
import { NavService } from './shared/services/nav.service';
import { DOCUMENT } from '@angular/common';
import { UtilsService } from './shared/services/utils.service';
import { Router, RouterEvent, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  private randomnumber = Math.floor(Math.random() * 11) + 1;
  private randomBackground = 'background-' + this.randomnumber + '.png';

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    public navServices: NavService,
    public utilService: UtilsService,
    router: Router
    )
  {
    let asyncLoadCount = 0;

        router.events.subscribe(
            ( event: RouterEvent ): void => {
                if ( event instanceof RouteConfigLoadStart ) {
                    asyncLoadCount++;
                } else if ( event instanceof RouteConfigLoadEnd ) {
                    asyncLoadCount--;
                }

                utilService.isLoading = !! asyncLoadCount;
            }
        );
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const offset = this.window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const layoutVersion = localStorage.getItem("layoutVersion");

    if(layoutVersion === 'dark-only'){
      if(offset > 1){
        document.getElementById("page-main-header").style.backgroundColor = '#000';
      }
      else{
        document.getElementById("page-main-header").style.backgroundColor = '#000D17';

      }
    }
    else{
      if(offset > 1){
        document.getElementById("page-main-header").style.backgroundColor = '#fff';
      }
      else{
        document.getElementById("page-main-header").style.backgroundColor = '#ffffffa6';
      }
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
      if ((<HTMLElement>event.target).tagName == "HTML") {
          document.getElementById("sidebar-toggle-expanded").style.display = 'none';
          document.getElementById("sidebar-toggle").style.display = 'block';
          this.navServices.collapseSidebar = true;
      }

      if(event.target != document.getElementsByClassName('Typeahead-menu')[0]){
        var container = <HTMLElement>document.getElementsByClassName('Typeahead-menu is-open')[0];
        if(container != undefined){
          if(!container.classList.contains('loader'))
          {
            container.classList.remove('is-open');
          }         
        }
       
    }
  }
}

