import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config: Config;

  constructor(private http: HttpClient) { }

  async loadConfig() {

    // console.debug("Loading configs...");
    const data = await this.http.get<Config>('./config.json')
      .toPromise();

    this.config = data;

    if (typeof this.config.enableSso === 'string') {
        this.config.enableSso = JSON.parse(this.config.enableSso);
    }
    // console.debug(`Configs has been loaded for environment "${this.config.environmentName}".`);

  }

  getConfig(): Config {
    return this.config;
  }
}

export interface Config {
  apiUrl: string;
  environmentName: string;
  enableSso: boolean;
  oidc: OidcConfig;
}

export interface OidcConfig {
  authority: string,
  client_id: string,
  redirect_uri: string,
  post_logout_redirect_uri: string,
  response_type: string,
  scope: string,
  filterProtocolClaims: boolean,
  loadUserInfo: boolean,
  automaticSilentRenew: boolean,
  silent_redirect_uri: string
}
