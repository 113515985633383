import { Component, OnInit } from '@angular/core';
import { AboutService } from '@app/core/services/about.service'
import { AboutDto } from '@app/core/models/about/about-dto';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentYear: string;
  appInfo: AboutDto[];

  constructor(private aboutService: AboutService) { }

  ngOnInit() { 
    this.currentYear = new Date().getFullYear().toString();
    this.appInfo = this.aboutService.getAppInfo();
  }

}
