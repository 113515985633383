///OpenCatapultModelId:960
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchSuggestionDto } from '../models/searchsuggestion/searchsuggestion-dto';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SearchSuggestionService {

  constructor(private apiService: ApiService) { }

  getSearchSuggestions(): Observable<SearchSuggestionDto[]> {
    return this.apiService.get<SearchSuggestionDto[]>('SearchSuggestion');
  }

  getSearchSuggestion(id: number): Observable<SearchSuggestionDto> {
    return this.apiService.get<SearchSuggestionDto>(`SearchSuggestion/${id}`);
  }

  createSearchSuggestion(dto: SearchSuggestionDto) {
    return this.apiService.post('SearchSuggestion', dto);
  }

  updateSearchSuggestion(dto: SearchSuggestionDto) {
    return this.apiService.put(`SearchSuggestion/${dto.id}`, dto);
  }

  deleteSearchSuggestion(id: number) {
    return this.apiService.delete(`SearchSuggestion/${id}`);
  }
}

