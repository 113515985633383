
import { FileService } from '../core/services/files.services'
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.css'],

})
export class UploadFilesComponent implements OnInit {

  @ViewChild('fileInput',{static:false}) fileInput:any;
  constructor(private fileService: FileService) { }

  ngOnInit() {
  }

  uploadPhoto(files: FileList){
    
    if (files.length === 0)
      return;

    let nativeElement: HTMLInputElement = this.fileInput.nativeElement;
    this.fileService.upload(files); 
  }

}
