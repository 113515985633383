import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventDto } from '../models/event/event-dto';
import { ApiService } from './api.service';
import { EventFilterResultDto } from "../models/event/event-filter-result-dto";
import { EventFilterDto } from "../models/event/event-filter-dto";
import { EventStatusDto } from "../models/event/event-status-dto";

@Injectable({
  providedIn: 'root'
})
export class WellnessEventService {

  constructor(private apiService: ApiService) { }

  getEvents(): Observable<EventDto[]> {
    return this.apiService.get<EventDto[]>(`WellnessEvent`);
  }

  getEvent(id: number): Observable<EventDto> {
    return this.apiService.get<EventDto>(`WellnessEvent/${id}`);
  }

  getEventByUrl(url: string): Observable<EventDto> {
    return this.apiService.get<EventDto>(`WellnessEvent/view/${url}`);
  }

  createEvent(dto: EventDto) {
    return this.apiService.post('WellnessEvent', dto);
  }

  updateEvent(dto: EventDto) {
    return this.apiService.put(`WellnessEvent/${dto.id}`, dto);
  }

  deleteEvent(id: number) {
    return this.apiService.delete(`WellnessEvent/${id}`);
  }

  getActiveEvents(skip: number, take: number): Observable<EventFilterResultDto> {
    return this.apiService.get<EventFilterResultDto>(`WellnessEvent/activeevents?skip=${skip}&take=${take}`);
  }

  getActiveWellnessEventsByNumber(take: number): Observable<EventFilterResultDto> {
    return this.apiService.get<EventFilterResultDto>(`WellnessEvent/activewellnessevents?take=${take}`);
  }

  getActiveTrainingSchedulesByNumber(take: number): Observable<EventFilterResultDto> {
    return this.apiService.get<EventFilterResultDto>(`WellnessEvent/activetrainingschedules?take=${take}`);
  }

  getActiveWellnessEvents(): Observable<EventFilterResultDto> {
    return this.apiService.get<EventFilterResultDto>(`WellnessEvent/activewellnessevents?`);
  }

  getActiveEventsForUser(filter: EventFilterDto): Observable<EventFilterResultDto> {
    return this.apiService.get<EventFilterResultDto>(`WellnessEvent/activeevents?skip=${filter.skip}&take=${filter.take}`);
  }

  getEventsByReserverId(): Observable<EventDto[]> {
    return this.apiService.get<EventDto[]>(`WellnessEvent/geteventsbyreserverid`);
  }

  archiveEvent(id: number) {
    return this.apiService.post(`WellnessEvent/Archive/${id}`);
  }
  
  getFilteredEvents(filter: EventFilterDto): Observable<EventFilterResultDto> {
    return this.apiService.get<EventFilterResultDto>(`WellnessEvent/Filtered/?statusFilter=${filter.status}&skip=${filter.skip}&take=${filter.take}&dateNow=${filter.dateNow}`);
  }

  getWellnessFilteredEvents(filter: EventFilterDto): Observable<EventFilterResultDto> {
    return this.apiService.get<EventFilterResultDto>(`WellnessEvent/WellnessFiltered/?statusFilter=${filter.status}&skip=${filter.skip}&take=${filter.take}&dateNow=${filter.dateNow}`);
  }

  getEventStatuses(): Observable<EventStatusDto[]> {
    return this.apiService.get<EventStatusDto[]>(`WellnessEvent/Statuses`);
  }

  cleanupEventImages() {
      return this.apiService.post(`WellnessEvent/CleanupEventImages`);
  }
}

