import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventService } from '@app/core/services/event.service';
import { BannerService } from '@app/core/services/banner.service';
import { GalleryService } from '@app/core/services/gallery.service';
import { BannerDto } from '@app/core//models/banner/banner-dto'
import { EventDto } from '@app/core/models/event/event-dto';
import { NewsArticleService } from '@app/core/services/newsarticle.service'
import { NewsArticleDto } from '@app/core/models/newsarticle/newsarticle-dto'
import { ActivatedRoute } from '@angular/router';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { NotificationTypeDto } from '@app/core/models/notificationtype/notificationtype-dto';
import { NotificationCustomDto } from '@app/core/models/notification/notification-custom-dto';
import { NewsArticleCustomDto } from '@app/core/models/newsarticle/newsarticlecustom-dto';
import { UserDto } from '@app/core/models/user/user-dto';
import { GalleryCustomDto } from '@app/core/models/gallery/gallery-custom-dto';
import { Router } from '@angular/router';
import { EventCustomForHomeDto } from '@app/core/models/event/event-custom-home-dto';
import { UtilsService } from '../shared/services/utils.service';
import { TimezoneService } from '@app/shared/services/timezone-service';
import { CovidDto } from '@app/core/models/covid/covid-dto';
import { CovidService } from '@app/core/services/covid.service';
import { WellnessEventHomeDto } from '../core/models/event/wellnessevent-home-dto';
import { EventReservationService } from '@app/core/services/eventreservation.service';
import { WellnessEventService } from '@app/core/services/wellnessevent.service';
import { TrainingScheduleService } from '../core/services/trainingschedule.service';
import { InstagramService } from '@app/core/services/instagram.service';
import { InstagramMediaDto } from '@app/core/models/instagram/instagrammedia-dto';
import { ConfirmationDialogService } from '@app/shared/services/confirmation-dialog.service';
import { PeopleSpotlightService } from '@app/core/services/peoplespotlight.service';
import { PeopleSpotlightDto } from '@app/core/models/user/people-spotlight-dto';
import { PeopleSpotlightFilterDto } from '@app/core/models/user/people-spotlight-filter-dto';
import { ConfigService } from '@app/config/config.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

interface BannerViewModel extends BannerDto {
  isExternalLink: boolean;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [NgbCarouselConfig]
})
export class HomeComponent implements OnInit, OnDestroy {
  activeEvents: EventCustomForHomeDto[] = [];
  gallery: GalleryCustomDto;
  covid: CovidDto;
  covidPageIsEnabled: boolean;
  publishedArticles: NewsArticleCustomDto[];
  topNewsArticle: NewsArticleCustomDto;
  seconds: number;
  date: any;
  bannerLoaded = false;
  loadMoreSpotlightFlag = true;
  bannerImages: BannerViewModel[];
  currentDate = new Date();
  isDismissed = false;
  eventsGroup: { eventDate: Date, eventDateString: string, events: EventCustomForHomeDto[] }[] = [];
  showNavigationArrows = true;
  showNavigationIndicators = true;
  currentTimezone: string;
  wellnessActiveEvents: WellnessEventHomeDto[] = [];
  instagramMedias: InstagramMediaDto[] = [];
  selectedEventOption: string;
  timer: any;
  images: any;
  showSpotlightButton: boolean = true;
  peopleSpotlightFilter: PeopleSpotlightFilterDto;
  peopleSpotlights: PeopleSpotlightDto[] = [];
  spotlightOptions: any[];
  currentMonth: string;

  events: {
    year: number;
    month: number;
    day: number;
    text: string;
  }[] = [];

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private newsArticleService: NewsArticleService,
    private bannerService: BannerService,
    private galleryService: GalleryService,
    private utilsService: UtilsService,
    carouselConfig: NgbCarouselConfig,
    private router: Router,
    private timezoneService: TimezoneService,
    private covidService: CovidService,
    private eventReservationService: EventReservationService,
    private wellnessEventService: WellnessEventService,
    private wellnessTrainingScheduleService: TrainingScheduleService,
    private instagramService: InstagramService,
    private dialog: ConfirmationDialogService,
    private toastrService: ToastrService,
    private peopleSpotlightService: PeopleSpotlightService,
    private config: ConfigService
  ) {
    carouselConfig.showNavigationArrows = true;
    carouselConfig.showNavigationIndicators = true;
  }

  ngOnInit() {
    this.selectedEventOption = 'company';
    this.getGroupActiveCompanyEvents(0, 5);

    // Get Published Article
    this.newsArticleService.getActiveArticles(0, 4, false).subscribe(publishedArticles => {
      this.publishedArticles = publishedArticles.results;
      this.topNewsArticle = this.publishedArticles[0];
      this.topNewsArticle.imageUrl = this.topNewsArticle.imageUrl.replace(' ', '%20');

    });

    //Get Active Banners
    this.bannerService.getActiveBanners().subscribe(bannerImages => {
      this.bannerImages = bannerImages.map(result => {
        return {
          isExternalLink: this.utilsService.isExternalLink(result.url),
          ...result
        };
      });;
      if (bannerImages.length <= 0) {
        this.showNavigationArrows = false;
        this.showNavigationIndicators = false;
      }
    });

    //Get Latest Published Gallery
    this.galleryService.getPublishedGallery(0, 1).subscribe(galleries => {
      this.gallery = galleries[0];
    });

    // Get COVID-19 Data
    this.covidPageIsEnabled = false;
    this.covidService.getPublished().subscribe(covidData => {
      this.covid = covidData;
      let today = new Date();
      this.covidPageIsEnabled = this.covid && !this.covid.disabled;
    });

    // Get Wellness Events
    this.getActiveWellnessEvents();

    // Get Instagram Medias
    this.getInstagramMedias();

    this.peopleSpotlightFilter = {
      skip: 0,
      take: 5,
      types: []
    };

    this.spotlightOptions = [
      { type: 'anniversary', header: 'Anniversaries', isSelected: false },
      { type: 'new_employee', header: 'New employees', isSelected: false },
      { type: 'promotion', header: 'Promotions', isSelected: false }
    ];

    // Get People Spotlight
    this.getPeopleSpotlights();

    this.currentTimezone = this.timezoneService.checkTimeZone();

    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    this.currentMonth = monthNames[new Date().getUTCMonth()].toUpperCase();
  }

  //TODO: We need a way to get the author's first name. At a minimum, we could create a service in the middle tier for getting a user profile by email address.
  getAuthorName() {
    return "Matt";
  }

  ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  dismissAnnouncement(note) {
    this.isDismissed = true;
  }

  isEvent(date: NgbDate): string {
    const holiday = this.events.find(h => h.day === date.day && h.month === date.month);
    return holiday ? holiday.text : '';
  }


  getTooltip(date: NgbDate) {
    const holidayTooltip = this.isEvent(date);

    if (holidayTooltip) {
      return holidayTooltip;
    } else {
      return '';
    }
  }

  isDisabled(date: NgbDateStruct) {
    const d = new Date(date.year, date.month - 1, date.day);
    var currentdate = new Date();

    return d.getMonth() != currentdate.getMonth() && d.getFullYear() === currentdate.getFullYear();
  }

  openGallery(canonicalUrl: string) {
    this.router.navigateByUrl('/gallery/' + canonicalUrl);
  }

  openCovid() {
    this.router.navigateByUrl('/legendarynews');
  }

  goToJobAds() {
    window.open("https://recruiting2.ultipro.com/LEG1005LEGEN/JobBoard/cf206330-faba-4b99-b133-a4f4e5953800/?q=&o=postedDateDesc", "_blank");
  }

  onRegisterButtonClick(event) {
    this.registerTraining(event);
  }

  registerWellness(event) {
    this.eventReservationService.getEventReservationsByEventId(event.id).subscribe(eventReservations => {
      let eventReservationTotal = eventReservations.length;
      if (eventReservationTotal < event.maxParticipant) {
        this.eventReservationService.registerEvent(event.id).subscribe(() => {
          this.toastrService.success("You have been registered for " + event.name, "Success");
          this.getActiveWellnessEvents();
        });
      } else {
        this.toastrService.error("Registration Capacity Cap has been reached", "Error");
      }
    });
  }

  registerTraining(event) {
    this.wellnessTrainingScheduleService.getTrainingScheduleByReserverAndWeek(event.id).subscribe(data => {
      if (data == null) {
        this.wellnessTrainingScheduleService.registerusertrainingschedule(event.id).subscribe(status => {
          this.getActiveWellnessEvents();
          this.toastrService.success("You have been registered for training session with " + event.name.replace('Training Schedule ', ''), "Success");
        });
      }
      else {
        this.dialog.open(`Reserve the ${moment(event.eventStartDate).format("dddd, MMMM DD, YYYY h:mm a")} session with ${event.name.replace('Training Schedule ', '')}`,
          `You already have a session booked for this week. Would you like to change it to this day at ${moment(event.eventStartDate).format("dddd, MMMM DD, YYYY h:mm a")} with ${event.name.replace('Training Schedule ', '')} instead?`)
          .then(result => {
            if (result) {
              this.wellnessTrainingScheduleService.updateReserveTrainingSchedule(event.id, data.id).subscribe(() => {
                this.getActiveWellnessEvents();
                this.toastrService.success("You have been registered for training session with " + event.name.replace('Training Schedule ', ''), "Success")
              });
            }
          }).catch(() => console.log('dismissed'));
      }
    });
  }

  getActiveWellnessEvents() {
    this.wellnessActiveEvents = [];
    this.wellnessEventService.getActiveTrainingSchedulesByNumber(4).subscribe(events => {
      events.results.forEach(event => {
        this.eventReservationService.getEventReservationsByReserverIdAndEventId(event.id).subscribe(eventReservation => {
          this.wellnessActiveEvents.push({
            id: event.id,
            name: event.name,
            eventStartDate: event.eventStartDate,
            canonicalUrl: event.canonicalUrl,
            eventType: event.eventType,
            eventStartDateString: new Date(event.eventStartDate.toString()).toDateString(),
            description: event.description,
            thumbnailUrl: event.thumbnailUrl,
            maxParticipant: event.maxParticipant,
            isRegistered: eventReservation.length > 0
          });
        });
      });
    });
  }

  getInstagramMedias() {
    this.instagramMedias = [];
    this.instagramService.getMediaCollection().subscribe(medias => {
      medias.forEach(media => {
        this.instagramMedias.push({
          id: media.id,
          caption: media.caption,
          media_type: media.media_type,
          media_url: media.media_url,
          thumbnail_url: media.thumbnail_url,
          profile_picture_url: media.profile_picture_url,
          shortcode: media.shortcode,
          twitter_url: media.twitter_url,
          facebook_url: media.facebook_url,
          username: media.username,
          timestamp: media.timestamp,
          timestampString: new Date(media.timestamp.toString()).toDateString()
        });
      });
    });

  }

  getGroupActiveCompanyEvents(skip: number, take: number) {
    // Get Active Events List
    this.eventService.getActiveEventsByType(skip, take, 'General').subscribe(activeEvents => {
      activeEvents.results.forEach(element => {
        this.activeEvents.push({
          id: element.id,
          name: element.name,
          description: element.description,
          eventStartDate: element.eventStartDate,
          eventEndDate: element.eventEndDate,
          imageUrl: element.imageUrl,
          canonicalUrl: element.canonicalUrl,
          eventStartDateString: new Date(element.eventStartDate.toString()).toDateString()
        });
      });

      //Event Grouping
      let groupArr = this.activeEvents.reduce((r, { eventStartDate, eventStartDateString }) => {
        if (!r.some(o => o.group == eventStartDateString)) {
          r.push({ eventStartDate, eventStartDateString, groupItem: this.activeEvents.filter(v => v.eventStartDateString == eventStartDateString) });
        }
        return r;
      }, []);

      groupArr.forEach(element => {
        if (this.eventsGroup.filter(x => x.eventDateString === element.eventStartDateString).length <= 0) {
          let events: EventCustomForHomeDto[] = []
          element.groupItem.forEach(item => {
            events.push({
              id: item.id,
              name: item.name,
              description: item.description,
              eventStartDate: new Date(item.eventStartDate),
              eventEndDate: new Date(item.eventEndDate),
              imageUrl: item.imageUrl,
              canonicalUrl: item.canonicalUrl,
              eventStartDateString: item.eventStartDateString
            });
          });

          this.eventsGroup.push({
            eventDate: element.eventStartDate,
            eventDateString: element.eventStartDateString,
            events: events
          });
        }
      });
    });
  }

  getGroupActiveWellnessEvents(skip: number, take: number) {
    // Get Active Events List
    this.eventService.getActiveEventsByType(skip, take, 'WellnessGroupEvent').subscribe(activeEvents => {
      activeEvents.results.forEach(element => {
        this.activeEvents.push({
          id: element.id,
          name: element.name,
          description: element.description,
          eventStartDate: element.eventStartDate,
          eventEndDate: element.eventEndDate,
          imageUrl: element.imageUrl,
          canonicalUrl: element.canonicalUrl,
          eventStartDateString: new Date(element.eventStartDate.toString()).toDateString()
        });
      });

      //Event Grouping
      let groupArr = this.activeEvents.reduce((r, { eventStartDate, eventStartDateString }) => {
        if (!r.some(o => o.group == eventStartDateString)) {
          r.push({ eventStartDate, eventStartDateString, groupItem: this.activeEvents.filter(v => v.eventStartDateString == eventStartDateString) });
        }
        return r;
      }, []);

      groupArr.forEach(element => {
        if (this.eventsGroup.filter(x => x.eventDateString === element.eventStartDateString).length <= 0) {
          let events: EventCustomForHomeDto[] = []
          element.groupItem.forEach(item => {
            events.push({
              id: item.id,
              name: item.name,
              description: item.description,
              eventStartDate: new Date(item.eventStartDate),
              eventEndDate: new Date(item.eventEndDate),
              imageUrl: item.imageUrl,
              canonicalUrl: item.canonicalUrl,
              eventStartDateString: item.eventStartDateString
            });
          });

          this.eventsGroup.push({
            eventDate: element.eventStartDate,
            eventDateString: element.eventStartDateString,
            events: events
          });
        }
      });
    });
  }

  selectUpcomingEvents(option: string) {
    if (option && option === this.selectedEventOption) {
      return;
    }

    this.activeEvents = [];
    this.eventsGroup = [];
    this.selectedEventOption = option;

    switch (this.selectedEventOption) {
      case 'company':
        this.getGroupActiveCompanyEvents(0, 5);
        break;
      case 'wellness':
        this.getGroupActiveWellnessEvents(0, 5);
        break;
    }
  }

  loadMoreEvents() {
    switch (this.selectedEventOption) {
      case 'company':
        this.getGroupActiveCompanyEvents(this.activeEvents.length, 5);
        break;
      case 'wellness':
        this.getGroupActiveWellnessEvents(this.activeEvents.length, 5);
        break;
    }
  }

  loadMoreSpotlights() {
    this.peopleSpotlightFilter.skip += 5;
    this.getPeopleSpotlights();
  }

  onAllSpotlightChanged() {
    this.peopleSpotlightFilter.types = [];

    // reset checkbox
    for (var i = 0; i < this.spotlightOptions.length; i++) {
      this.spotlightOptions[i].isSelected = false;
    }

    this.getPeopleSpotlights();
  }

  onSpotlightChanged(event: any) {
    const isChecked = event.target.checked;
    const value = event.target.value;
    if (isChecked) {
      this.peopleSpotlightFilter.types.push(value);
    } else {
      this.peopleSpotlightFilter.types = this.peopleSpotlightFilter.types.filter(e => e !== value)
    }
    this.showSpotlightButton = true;
    this.peopleSpotlights = [];
    this.peopleSpotlightFilter.skip = 0;
    this.getPeopleSpotlights();
  }

  getPeopleSpotlights() {
    this.loadMoreSpotlightFlag = true;
    this.peopleSpotlightService.getFiltered(this.peopleSpotlightFilter).subscribe(data => {
      data.forEach(media => {
        this.peopleSpotlights.push({
          id: media.id,
          header: media.header,
          message: media.message,
          imageUrl: media.imageUrl
        });
        if (data.length < 5) {
          this.showSpotlightButton = false;
        }
      });
      //this.peopleSpotlights = data;
      this.loadMoreSpotlightFlag = false;
    });
  }

  getImageUrl(userId: string) {
    return `${this.config.getConfig().apiUrl}/userprofile/${userId}/photo`;
  }
}
