import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

@Injectable({
    providedIn: 'root'
})

export class TimezoneService {
    timeZoneSetting: string = "America/Los_Angeles";
    constructor() { }
    
    checkTimeZone(){
        return moment.tz(new Date,this.timeZoneSetting).isDST() ? 'PDT' : 'PST';
    }
}