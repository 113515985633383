import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {
  error: string;
  errorDescription: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    // check for error
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        const res = new URLSearchParams(fragment);
        this.error = res.get('error');
        this.errorDescription = res.get('error_description');
      }
    });

    this.route.queryParams.subscribe(params => {
      if (params) {
        const res = new URLSearchParams(params);
        this.error = res.get('error');
        this.errorDescription = res.get('error_description');
      }
    })

    await this.authService.completeAuthentication();
    this.router.navigate(['/']);
  }

}
