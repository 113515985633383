import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class FileService {

    constructor(private apiService: ApiService) { }


    upload(file: any) {
        let input = new FormData();
        input.append("filesData", file);
        return this.apiService.post('Document/UploadFiles', input);
    }

    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]);

      let n = bstr.length;

      const u8arr = new Uint8Array(n);

      while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {type: mime});
  }
}
