import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  isLoading: boolean;

  constructor() { }
   
  isExternalLink(link: string): boolean {
    if (link) {
      if (link.startsWith('/')) {
        return false;
      }

      return !link.toLowerCase().includes(window.location.hostname.toLowerCase());
    }
  }

  getDefaultTextEditorToolbars(): string[][] {
    return [
      ['bold', 'italic', 'underline', 'strikeThrough', 'superscript', 'subscript'],
      ['fontName', 'fontSize', 'color'],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
      ['cut', 'copy', 'delete', 'removeFormat', 'undo', 'redo'],
      ['paragraph', 'blockquote', 'removeBlockquote', 'horizontalLine', 'orderedList', 'unorderedList'],
      ['link', 'unlink', 'image', 'video', 'html']
    ];
  }

  getCovidTextEditorToolbars(): string[][] {
    return [
      ['bold', 'italic', 'underline', 'strikeThrough', 'superscript', 'subscript'],
      ['fontName', 'fontSize', 'color'],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
      ['cut', 'copy', 'delete', 'removeFormat', 'undo', 'redo'],
      ['paragraph', 'blockquote', 'removeBlockquote', 'horizontalLine', 'orderedList', 'unorderedList'],
      ['link', 'unlink', 'image', 'video', 'html']
    ];
  }

  toogleLoadingIndicator(show: boolean) {
    this.isLoading = show;
  }
}
