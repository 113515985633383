import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from '@app/core/auth/auth.guard';
import { AuthorizePolicy } from '@app/core/auth/authorize-policy';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { LoginComponent } from './login/login.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { ContentLayoutComponent } from './shared/components/layout/content-layout/content-layout.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';

const generatedRoutes = [
    /*** Generated Routing Begin ***/
{
  path: 'banner', loadChildren: './banner/banner.module#BannerModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Banner' }
},
{
  path: 'document', loadChildren: './document/document.module#DocumentModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Document' }
},
{
  path: 'documentcategory', loadChildren: './documentcategory/documentcategory.module#DocumentCategoryModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Document category' }
},
{
  path: 'documentgroup', loadChildren: './documentgroup/documentgroup.module#DocumentGroupModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Document group' }
},
{
  path: 'employeehighlight', loadChildren: './employeehighlight/employeehighlight.module#EmployeeHighlightModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Employee highlight' }
},
{
  path: 'event', loadChildren: './event/event.module#EventModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Event' }
},
{
  path: 'eventreservation', loadChildren: './eventreservation/eventreservation.module#EventReservationModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Event reservation' }
},
{
  path: 'gallery', loadChildren: './gallery/gallery.module#GalleryModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Gallery' }
},
{
  path: 'newsarticle', loadChildren: './newsarticle/newsarticle.module#NewsArticleModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'News article' }
},
{
  path: 'notification', loadChildren: './notification/notification.module#NotificationModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Notification' }
},
{
  path: 'notificationtype', loadChildren: './notificationtype/notificationtype.module#NotificationTypeModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Notification type' }
},
{
  path: 'personaltrainer', loadChildren: './personaltrainer/personaltrainer.module#PersonalTrainerModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Personal trainer' }
},
{
  path: 'photo', loadChildren: './photo/photo.module#PhotoModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Photo' }
},
{
  path: 'quicklink', loadChildren: './quicklink/quicklink.module#QuickLinkModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Quick link' }
},
{
  path: 'searchsuggestion', loadChildren: './searchsuggestion/searchsuggestion.module#SearchSuggestionModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Search suggestion' }
},
{
  path: 'trainingschedule', loadChildren: './trainingschedule/trainingschedule.module#TrainingScheduleModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Training schedule' }
},
{
  path: 'userphoto', loadChildren: './userphoto/userphoto.module#UserPhotoModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'User photo' }
},
{
  path: 'wellnessblog', loadChildren: './wellnessblog/wellnessblog.module#WellnessBlogModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Wellness blog' }
},
{
  path: 'wellnesscontent', loadChildren: './wellnesscontent/wellnesscontent.module#WellnessContentModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Wellness content' }
},
{
  path: 'wellnessquicklinks', loadChildren: './wellnessquicklinks/wellnessquicklinks.module#WellnessQuickLinksModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Wellness quick links' }
},
    /*** Generated Routing End ***/
{
  path: 'legendarynews', loadChildren: './covid/covid.module#CovidModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Legendary News' }
},
{
    path: 'pushnotification', loadChildren: './push-notification/push-notification.module#PushNotificationModule',
    data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Push Notification' }
},
{
    path: 'directory', loadChildren: './directory/directory.module#DirectoryModule',
    data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Directory' }
},
{
  path: 'wellness', loadChildren: './wellness/wellness.module#WellnessModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Wellness' }
},
{
  path: 'wellnessevent', loadChildren: './wellnessevent/wellnessevent.module#WellnesseventModule',
  data: { authPolicy: AuthorizePolicy.UserRoleAdminAccess, breadcrumb: 'Wellness Events' }
},
];

// override auth policy to UserRoleEditorAccess for some path
generatedRoutes.filter(x => ["banner", "document", "documentcategory", "event","legendarynews",
    "eventaccesslevel", "gallery", "newsarticle", "photo","documentgroup","wellness"].includes(x.path)).forEach(x => {
    x.data.authPolicy = AuthorizePolicy.UserRoleEditorAccess;
});

// override auth policy for welness
generatedRoutes.filter(x => x.path == 'wellness').forEach(x => {
  x.data.authPolicy = AuthorizePolicy.UserRoleWellnessAdminAccess;
});

generatedRoutes.filter(x => x.path == 'wellnessevent').forEach(x => {
  x.data.authPolicy = AuthorizePolicy.UserRoleWellnessEventAccess;
});



const routes: Routes = [
    {
        path: '',
        canActivateChild: [AuthGuard],
        component: ContentLayoutComponent,
        children: [
            {
                path: '', component: HomeComponent, pathMatch: 'full',
                data: {
                    title: 'Dashboard',
                    breadcrumb: 'Dashboard'
                }
            },
            {
                path: 'news',
                data: {
                    title: "news",
                    breadcrumb: "news"
                },
                loadChildren: './guest/newsarticle/newsarticle.module#NewsarticleModule'
            },
            {
                path: 'event',
                data: {
                    title: "event",
                    breadcrumb: "event"
                },
                loadChildren: './guest/event/event.module#EventModule'
            },
            {
                path: 'gallery',
                data: {
                    title: "Photo Gallery",
                    breadcrumb: "Photo Gallery"
                },
                loadChildren: './guest/gallery/gallery.module#GalleryModule'
            },
            {
                path: 'document',
                data: {
                    title: "Document",
                    breadcrumb: "Document"
                },
                loadChildren: './guest/document/document.module#DocumentModule'
            },
            {
                path: 'legendarynews',
                data: {
                    title: "Legendary News",
                    breadcrumb: "Legendary News"
                },
                loadChildren: './guest/covid/covid.module#CovidModule'
            },
            {
              path: 'directory',
              data: {
                  title: "Corporate Directory",
                  breadcrumb: "Corporate Directory"
              },
              loadChildren: './directory/directory.module#DirectoryModule'
            },
            {
                path: 'launchpad',
                data: {
                    title: "Launchpad",
                    breadcrumb: "Launchpad"
                },
                loadChildren: './guest/launchpad/launchpad.module#LaunchpadModule'
            },
            {
              path: 'searchresult',
              data: {
                  title: "searchresult",
                  breadcrumb: "searchresult"
              },
              loadChildren: './search-result/search-result.module#SearchResultModule'
            },
            {
              path: 'wellness',
              data: {
                  title: "Wellness",
                  breadcrumb: "Wellness",
                  authPolicy: AuthorizePolicy.UserRoleWellnessAccess
              },
              loadChildren: './guest/wellness/wellness.module#WellnessModule'
            },
           
        ]
    },
    {
        path: 'admin',
        canActivateChild: [AuthGuard],
        component: ContentLayoutComponent,
        children: [
            ...generatedRoutes
        ]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'unauthorized',
        component: UnauthorizedComponent
    },
    {
        path: 'upload-files',
        component: UploadFilesComponent
    },
    {
      path: 'auth-callback',
      component: AuthCallbackComponent
    },
    { path: "**", redirectTo:''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
