import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { ConfigServiceProvider } from './config/config.service.provider';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './login/login.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { CoreModule } from './core/core.module';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { FileService } from './core/services/files.services'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { ToastrModule } from 'ngx-toastr';
import { EllipsisModule } from 'ngx-ellipsis';
import {DatePipe} from '@angular/common';


@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        HomeComponent,
        LoginComponent,
        UnauthorizedComponent,
        UploadFilesComponent,
        AuthCallbackComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        AppRoutingModule,
        SharedModule,
        CoreModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            preventDuplicates: true
        }),
        ReactiveFormsModule,
        NgbModule,
        EllipsisModule
    ],
    providers: [
        ConfigServiceProvider, FileService, DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
