///OpenCatapultModelId:925
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { SearchDto } from '@app/core/models/search/search-dto'

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public currentResultCountSubject: BehaviorSubject<number>;
  public currentResultCount: Observable<number>;

  constructor(private apiService: ApiService) {
    this.currentResultCountSubject = new BehaviorSubject<number>(-1);
    this.currentResultCount = this.currentResultCountSubject.asObservable();
   }

  search(searchString: string): Observable<SearchDto> {
    return this.apiService.get<SearchDto>(`Search/${searchString}`);
  }

  searchByEntity(keyword:string, entity:string): Observable<SearchDto>{
    return this.apiService.get<SearchDto>(`Search/${keyword}?searchEntity=${entity}`);
  }
}
