import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../core/auth/auth.service';
import { Config, ConfigService } from '@app/config/config.service';
import { User } from '@app/core/auth/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loading = false;
  returnUrl: string;
  error = '';
  loginForm = this.fb.group({
    userName: [null, Validators.compose([Validators.required,Validators.email])],
    password: [null, Validators.required]
  });
  submitted = false;
  config: Config;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private configService: ConfigService) {
    if (this.authService.currentUserValue) {
      this.router.navigate(['/']);
    }

    this.config = this.configService.getConfig();
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = '/';
    this.route.queryParams.subscribe(data => {
      if (data.returnUrl) {
        this.returnUrl = data.returnUrl;
      }
    });

    if (this.config.enableSso) {
        let user = <User>{
            returnUrl: this.returnUrl
        }

        this.authService.login(user)
            .subscribe(
                () => {
                    this.router.navigate([this.returnUrl]);
                },
                (err: HttpErrorResponse) => {
                    if (err.error && typeof err.error === 'string') {
                        this.error = err.error;
                    } else {
                        this.error = err.message;
                    }
                    this.loading = false;
                }
            );
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.loading = true;
      this.authService.login(this.loginForm.value)
        .subscribe(
          () => {
            this.router.navigate([this.returnUrl]);
          },
          (err: HttpErrorResponse) => {
            if (err.error && typeof err.error === 'string') {
              this.error = err.error;
            } else {
              this.error = err.message;
            }
            this.loading = false;
          });
    }
  }

  loginSso() {
    let user = <User> {
      returnUrl: window.location.href
    }

    this.authService.login(user)
      .subscribe(
        () => {
          this.router.navigate([this.returnUrl]);
        },
        (err: HttpErrorResponse) => {
          if (err.error && typeof err.error === 'string') {
            this.error = err.error;
          } else {
            this.error = err.message;
          }
          this.loading = false;
        }
      );
    }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
}
